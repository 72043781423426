import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import SideMenu, {
  UserMenu,
  UserNavMenu
} from 'components/header/side-menu';
import { Account } from 'components/header';
import Button from 'components/button';
import Row, { Col } from 'components/row';

import {
  renderLangSwitch,
  renderLinkConverter,
  renderOurContacts,
  // renderWebsiteAdvertising
} from './render-common';
import ExchangeRate from './exchange-rate';
import { renderModalAskUsQuestion } from '../footer';

import apps from 'src-sites/backoffice/apps';

import translates from './header-i18n';

const classes = new Bem('sideMenu');
const classesUserMenu = new Bem('userMenu');

@I18nHoc(translates())
export default class DomikSideMenu extends SideMenu {
  static displayName = 'DomikSideMenu';

  static propTypes = {
    ...SideMenu.propTypes,
    renderSearch: PropTypes.func
  };

  actionAuthLogout =()=> {
    if (this.props.onMenuClose) this.props.onMenuClose();
    if (this.props.actionAuthLogout) this.props.actionAuthLogout();
  }

  renderUserMenu() {
    const { i18n } = this;
    const {
      auth, desktop, onMenuClose, activeRoute,
      checkPermissions, checkPermission, actionAuthLogout
    } = this.props;
    const domikApps = apps;
    domikApps.addObjects.name = i18n('objectAdd');
    domikApps.profile.name = i18n('myProfile');
    domikApps.myObjects.name = i18n('myObjects');
    domikApps.billing.name = i18n('billing');
    const askButtonProps = {
      label: i18n('askQuestionBtn'),
      title: i18n('askQuestionBtn'),
      contentLeft: true,
      dataView: null
    };

    return (
      <UserMenu
        userData={auth}
        desktop={desktop}
        onCloseSideMenu={onMenuClose}
      >
        <div {...classesUserMenu('content')}>
          {/*
          <Menu
            mainMenu={mainMenu}
            onCloseSideMenu={onMenuClose}
          />
          */}
          <UserNavMenu
            projectApps={domikApps}
            checkPermissions={checkPermissions}
            checkPermission={checkPermission}
            actionAuthLogout={actionAuthLogout}
            onCloseSideMenu={onMenuClose}
            name={get(domikApps, 'profile.name')}
          />
          <Row {...classes('item', 'change')}>
            <Col {...classes('col')} allPr={0}>
              <ExchangeRate {...classes('exchangeRate')} linkProps={{ title: i18n('converter') }} />
              {renderLinkConverter(classes, i18n('converter'))}
            </Col>
            {renderLangSwitch(classes, activeRoute)}
          </Row>
          <div {...classes('item', 'info')}>
            {renderOurContacts.call(this, classes)}
            <div {...classes('links')}>
              {renderModalAskUsQuestion.call(this, askButtonProps, classes)}
              {/*
              {renderWebsiteAdvertising(classes, i18n('websiteAdvertising'))}
              */}
            </div>
          </div>
        </div>
      </UserMenu>
    );
  }

  renderMainMenu() {
    const { i18n } = this;
    const {
      onOpenMenu, auth = {} /*, mainMenu, onMenuClose */
    } = this.props;

    return (
      <>
        {/*
        <Menu
          mainMenu={mainMenu}
          onCloseSideMenu={onMenuClose}
          mobile
        />
        */}
        {!auth.authenticated &&
          <Account
            onAccountClick={onOpenMenu}
          />
        }
        {auth.authenticated &&
          <Button
            {...classes('btn', 'exit')}
            onClick={this.actionAuthLogout}
            // iconType={iconTypes.logOut}
            label={i18n('btnLogout')}
            title={i18n('btnLogout')}
          />
        }
      </>
    );
  }

  renderSearchTouch() {
    if (this.props.renderSearch) return this.props.renderSearch();
  }
}
