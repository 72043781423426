/**
 * Created by dryymoon on 06.03.17.
 */

export const mainMenu = {
  arenda: { text: 'Аренда', to: '/arenda/', order: 100 },
  prodaza: { text: 'Продажа', to: '/kupit/', order: 200 },
  novostroyki: { text: 'Новостройки', to: '/novostrojki/', order: 300 },
  tseny: { text: 'Цены', to: '/ceny/', order: 400 },
  specialisty: { text: 'Специалисты', to: '/kompanii/', order: 500 }, // Возможно будет другой урл
  novosti: { text: 'Новости', to: '/novosti/', order: 600 },
  forum: { text: 'Форум', to: 'http://forum.domik.ua', order: 700 },
};

export const objectUrl = 'object/*-id:id.html';
export const objectToUrl = ({ id, url }) => `object/${url}-id${id}.html`;

export const newsItemUrl = `${mainMenu.novosti.to}/*-id:id.html`;
export const newsItemToUrl = ({ id, url }) => `${mainMenu.novosti.to}/${url}-id${id}.html`;

// export const phoneDomik = '+380(44) 581 15 25';
export const phoneDomik = '+380(98) 656 34 02';

export const emailDomik = 'domik.domikua@gmail.com';

export default [];
