import React from 'react';
import replace from 'lodash/replace';
import Link from 'components/link';
import Row, { Col } from 'components/row';
import LangSwitch from 'components/lang-switch';
import Icon, { iconTypes } from 'components/icon';

import { phoneDomik } from 'src-sites/domik/config';
import linksDomik from 'src-sites/domik/links';

export function renderLinkObjectAdd(classes, name) {
  return (
    <Link
      {...classes('link', 'addObject')}
      builder={linksDomik.pageObjectAdd}
      builderProps={{ title: name }}
      noRedirector
    >
      <span {...classes('linkContent')}>
        <span {...classes('text')} children={name} />
      </span>
    </Link>
  );
}

export function renderLinkMyObjects(classes, name) {
  return (
    <Link
      {...classes('link')}
      builder={linksDomik.pageMyObjects}
      builderProps={{ title: name }}
    >
      <span {...classes('linkContent')}>
        <span {...classes('text')} children={name} />
      </span>
    </Link>
  );
}

export function renderLinkConverter(classes, name) {
  return (
    <Link
      {...classes('link', 'toConverter')}
      builder={linksDomik.sectionConverter}
      builderProps={{ title: name }}
      noRedirector
    >
      <span {...classes('linkContent')}>
        <Icon {...classes('icon')} type={iconTypes.converter} />
      </span>
    </Link>
  );
}

export function renderLangSwitch(classes, activeRoute) {
  if (!activeRoute) return null;

  return (
    <Col {...classes('col', 'langSwitch')} allAuto allPr={0}>
      <LangSwitch {...classes('langSwitch')} activeRoute={activeRoute} switchWithoutPages />
    </Col>
  );
}

export function renderWebsiteAdvertising(classes, name) {
  return (
    <Link
      {...classes('link', 'toWebsiteAdvertising')}
      href="#" // TODO: Дима ссылка должна ввести туда же куда и линк "Реклама" в футере (самой странички пока нет)
      external
      noRedirector
    >
      <span {...classes('linkContent')}>
        <span {...classes('text')} children={name} />
      </span>
    </Link>
  );
}

export function renderOurContacts(classes) {
  const { i18n } = this;
  const telText = phoneDomik;
  const department = i18n('department');

  return (
    <Row {...classes('row', 'tel')}>
      <Col {...classes('col', 'time')} allPr={0}>
        <div {...classes('text')} children={i18n('departmentTopic')} />
        <span {...classes('text', 'time')} children={department} />
      </Col>
      <Col {...classes('col', 'call')} allAuto allPr={0}>
        <div {...classes('text')} children={i18n('call')} />
        <Link
          {...classes('link', 'tel')}
          href={`tel:${replace(telText, /[\s\(\)]/g, '')}`}
          external
          noRedirector
        >
          <span {...classes('linkContent')}>
            <span {...classes('text')} children={telText} />
          </span>
        </Link>
      </Col>
    </Row>
  );
}
