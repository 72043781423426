import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import get from 'lodash/get';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import isEqual from 'lodash/isEqual';
import findIndex from 'lodash/findIndex';
import { I18nHoc } from 'helpers/i18n';
import sensor from 'components/sensor';
import Link from 'components/link';
import Button from 'components/button';
import Row, { Col } from 'components/row';
import DropDown from 'components/drop-down';
import Icon, { iconTypes } from 'components/icon';

import {
  renderLangSwitch,
  renderLinkConverter,
  renderOurContacts,
  // renderWebsiteAdvertising,
  renderLinkObjectAdd
} from './render-common';
import ExchangeRate from './exchange-rate';
import { renderModalAskUsQuestion } from '../footer';

import config from '../../../../config';

import linksForum from 'src-sites/forum/links';
import linksDomik from 'src-sites/domik/links';

import translates from './header-i18n';

const classesBem = new Bem('hamburger');

@I18nHoc(translates())
@sensor
export default class Hamburger extends Component {
  static propTypes = {
    className: PropTypes.string,
    mainMenu: PropTypes.arrayOf(PropTypes.shape({})),
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    rubricTree: PropTypes.arrayOf(PropTypes.shape({})),
    rootForums: PropTypes.arrayOf(PropTypes.shape({})),
    location: PropTypes.shape({})
  }

  constructor(props, ctx) {
    super(props, ctx);
    this.onHandleResize = this.onHandleResize.bind(this);
    this.onResize = this.onHandleResize;

    this.state = {
      menuOpened: false,
      device: 'phone'
    };
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return this.state.menuOpened !== nextState.menuOpened ||
      !isEqual(nextProps.location, this.props.location) ||
      !isEqual(nextProps.rubricTree, this.props.rubricTree) ||
      !isEqual(nextProps.rootForums, this.props.rootForums) ||
      !isEqual(nextProps.activeRoute, this.props.activeRoute) ||
      this.state.device !== nextState.device ||
      get(nextProps, 'activeRoute.lang') !== get(this, 'props.activeRoute.lang')
      ;
  }

  componentDidMount() {
    this.onHandleResize(this.sensor.getRuntime());
  }

  onHandleResize({ media: { desktop, tablet, phone } = {} }) {
    const { device } = this.state;
    if (desktop && device !== 'desktop') return this.setState({ device: 'desktop' });
    if (tablet && device !== 'tablet') return this.setState({ device: 'tablet' });
    if (phone && device !== 'phone') return this.setState({ device: 'phone' });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevPathname = get(prevProps, 'location.pathname') || get(prevProps, 'activeRoute.path');
    const currentPathname = get(this, 'props.location.pathname') || get(this, 'props.activeRoute.path');
    if ((prevPathname !== currentPathname) && this.state.menuOpened) {
      const langUrl = get(this, 'props.activeRoute.langs', {});
      let needCloseDD = true;

      forEach(langUrl, pathname => {
        if (prevPathname === pathname) {
          return needCloseDD = false;
        }
      });

      if (needCloseDD && this.ddRef) this.ddRef.getInstance().close();
    }
  }

  onOpen=()=> {
    if (this.props.onOpen) this.props.onOpen();
    this.setState({ menuOpened: true });
  }

  onClose=()=> {
    if (this.props.onClose) this.props.onClose();
    this.setState({ menuOpened: false });
  }

  renderLandingLink(landingLink, linkKey) {
    return (
      <li {...classesBem('li')} key={linkKey}>
        <Link
          {...classesBem('link', 'item')}
          builder={landingLink.builder}
          builderProps={landingLink.builderProps}
          noRedirector
        >
          <span {...classesBem('linkContent')}>
            <span {...classesBem('text')} children={landingLink.hamburgerName} />
          </span>
        </Link>
      </li>
    );
  }

  render() {
    const { i18n } = this;
    const { menuOpened, device } = this.state;
    const { className, activeRoute, mainMenu, rubricTree, rootForums } = this.props;
    const urlToFB = config.fbDomik.url;
    const urlToTelegram = config.telegramlink.url;
    const isDesktop = device === 'desktop';
    const blockChanges = (
      <Row {...classesBem('item', 'change')}>
        <Col {...classesBem('col', 'converter')} allPr={0}>
          <ExchangeRate {...classesBem('exchangeRate')} linkProps={{ title: i18n('converter') }} />
          {renderLinkConverter(classesBem, i18n('converter'))}
        </Col>
        {renderLangSwitch(classesBem, activeRoute)}
      </Row>
    );
    const blockSocials = (
      <div {...classesBem('item', 'socials')}>
        <div {...classesBem('socials')}>
          <Link
            {...classesBem('link', 'social socialTelegram')}
            href={urlToTelegram}
            title={i18n('linkToTelegramTitle')}
            rel="nofollow"
            noRedirector
            external
            blank
          >
            <span {...classesBem('linkContent')}>
              <Icon {...classesBem('icon')} type={iconTypes.telegram} />
            </span>
          </Link>
          <Link
            {...classesBem('link', 'social socialFacebook')}
            href={urlToFB}
            title="facebook.com"
            rel="nofollow"
            noRedirector
            external
            blank
          >
            <span {...classesBem('linkContent')}>
              <Icon {...classesBem('icon')} type={iconTypes.socialFacebook} />
            </span>
          </Link>
        </div>
      </div>
    );
    const askButtonProps = {
      label: i18n('askQuestionBtn'),
      title: i18n('askQuestionBtn'),
      contentLeft: true,
      dataView: null
    };
    const houseCatalogIndex = findIndex(mainMenu, item => item.name === 'houseCatalog');
    const houseCatalogBuilderProps = get(mainMenu, `[${houseCatalogIndex}].builderProps`);

    return (
      <DropDown
        {...classesBem({
          modifiers: { opened: menuOpened },
          extra: className
        })}
        ref={ref => this.ddRef = ref}
        onOpen={this.onOpen}
        onClose={this.onClose}
        modalCommonProps={{
          className: 'modalHamburger'
        }}
        modalHeader={blockChanges}
        wAuto
      >
        <Button
          {...classesBem('btn', 'hamburger')}
          iconType={menuOpened? iconTypes.clear: iconTypes.menuDomik}
          iconOnly
        />
        <div {...classesBem('content')}>
          <Row {...classesBem('row', 'content')} allColumn>
            <Col {...classesBem('col', 'content links')} allPr={0}>
              {map(mainMenu, (itemMenu, key) => {
                const hasChildren = itemMenu.children && itemMenu.children.length;

                if (hasChildren) return (
                  <ul {...classesBem('ul')} key={key}>
                    <li {...classesBem('li', 'main')}>
                      <div {...classesBem('link', 'main')}>
                        <span {...classesBem('linkContent')}>
                          <span {...classesBem('text')} children={itemMenu.name} />
                        </span>
                      </div>
                    </li>
                    {map(itemMenu.children, (landingLink, linkKey) => (
                      this.renderLandingLink(landingLink, linkKey)
                    ))}
                  </ul>
                );
              })}
              <ul {...classesBem('ul')}>
                <li {...classesBem('li', 'main')}>
                  <Link
                    {...classesBem('link', 'main')}
                    builder={linksDomik.pageNewsRoot}
                    builderProps={{ name: 'publications', title: i18n('publications') }}
                    title={i18n('publications')}
                    noRedirector
                  >
                    <span {...classesBem('linkContent')}>
                      <span {...classesBem('text')} children={i18n('publications')} />
                    </span>
                  </Link>
                </li>
                {map((rubricTree || []), (item, key) => {
                  const name = item.name_short || item.name;

                  return (
                    <li {...classesBem('li')} key={key}>
                      <Link
                        {...classesBem('link', 'item')}
                        builder={linksDomik.sectionLanding}
                        builderProps={item}
                        title={name}
                        noRedirector
                      >
                        <span {...classesBem('linkContent')}>
                          <span {...classesBem('text')} children={name} />
                        </span>
                      </Link>
                    </li>
                  );
                })}
              </ul>
              <ul {...classesBem('ul')}>
                <li {...classesBem('li', 'main')}>
                  <Link
                    {...classesBem('link', 'main')}
                    builder={linksForum.sectionForum}
                    builderProps={{ name: 'forum', title: i18n('forum') }}
                    title={i18n('forum')}
                    noRedirector
                  >
                    <span {...classesBem('linkContent')}>
                      <span {...classesBem('text')} children={i18n('forum')} />
                    </span>
                  </Link>
                </li>
                {map((rootForums || []), (item, key) => {
                  const name = item.forum_title;

                  return (
                    <li {...classesBem('li')} key={key}>
                      <Link
                        {...classesBem('link', 'item')}
                        builder={linksForum.sectionForums}
                        builderProps={item}
                        title={name}
                        noRedirector
                      >
                        <span {...classesBem('linkContent')}>
                          <span {...classesBem('text')} children={name} />
                        </span>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </Col>
            <Col {...classesBem('col', 'content change')} allPr={0}>
              <Link
                {...classesBem('link', 'main')}
                builder={linksDomik.sectionLanding}
                builderProps={houseCatalogBuilderProps}
                noRedirector
              >
                <span {...classesBem('linkContent')}>
                  <span {...classesBem('text')} children={i18n('websiteAboutHouses')} />
                </span>
              </Link>
              {isDesktop &&
                <>
                  {blockChanges}
                  {blockSocials}
                </>
              }
            </Col>
            <Col {...classesBem('col', 'content info')} allPr={0}>
              <div {...classesBem('item', 'info')}>
                <div {...classesBem('links')}>
                  {renderModalAskUsQuestion.call(this, askButtonProps, classesBem)}
                  {/*
                  {renderWebsiteAdvertising(classesBem, i18n('websiteAdvertising'))}
                  */}
                </div>
                {renderOurContacts.call(this, classesBem)}
                {isDesktop && renderLinkObjectAdd(classesBem, i18n('objectAdd'))}
                {!isDesktop && blockSocials}
              </div>
            </Col>
            {!isDesktop &&
              <Col {...classesBem('col', 'content sticky')} allPr={0}>
                {renderLinkObjectAdd(classesBem, i18n('objectAdd'))}
              </Col>
            }
          </Row>
        </div>
      </DropDown>
    );
  }
}
