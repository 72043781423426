import linksForum from 'src-sites/forum/links';
import linksDomik from 'src-sites/domik/links';

const domikMenu = [
  // Section: Rent
  {
    builder: linksDomik.sectionLanding,
    contentType: 'objectsRent',
    children: [
      {
        builder: linksDomik.sectionLanding,
        contentType: 'objectsRentFlat'
      },
      {
        builder: linksDomik.sectionLanding,
        contentType: 'objectsRentHouse'
      },
      // {
      //   builder: linksDomik.sectionLanding,
      //   contentType: 'objectsRentRoom'
      // },
      // {
      //   builder: linksDomik.sectionLanding,
      //   contentType: 'objectsRentStoreroom'
      // },
      {
        builder: linksDomik.sectionLanding,
        contentType: 'objectsRentOffice'
      },
      {
        builder: linksDomik.sectionLanding,
        contentType: 'objectsRentGarage'
      },
      // {
      //   builder: linksDomik.sectionLanding,
      //   contentType: 'objectsRentMarketplace'
      // },
      // {
      //   builder: linksDomik.sectionLanding,
      //   contentType: 'objectsRentFreePurpose'
      // },
      // {
      //   builder: linksDomik.sectionLanding,
      //   contentType: 'objectsRentWarehouse'
      // },
      // {
      //   builder: linksDomik.sectionLanding,
      //   contentType: 'objectsRentProduction'
      // },
      // {
      //   builder: linksDomik.sectionLanding,
      //   contentType: 'objectsRentBusiness'
      // },
      // {
      //   builder: linksDomik.sectionLanding,
      //   contentType: 'objectsRentBuilding'
      // },
      {
        builder: linksDomik.sectionLanding,
        contentType: 'objectsRentLandFarm'
      },
      {
        builder: linksDomik.sectionLanding,
        contentType: 'objectsRentFlatDaily'
      },
      // {
      //   builder: linksDomik.sectionLanding,
      //   contentType: 'objectsRentHouseDaily'
      // },
      // {
      //   builder: linksDomik.sectionLanding,
      //   contentType: 'objectsRentRoomDaily'
      // },
      // {
      //   builder: linksDomik.sectionLanding,
      //   contentType: 'objectsRentBedDaily'
      // }
    ]
  },
  // Section: Sale
  {
    builder: linksDomik.sectionLanding,
    contentType: 'objectsSale',
    children: [
      {
        builder: linksDomik.sectionLanding,
        contentType: 'objectsSaleFlat'
      },
      {
        builder: linksDomik.sectionLanding,
        contentType: 'objectsSaleHouse'
      },
      // {
      //   builder: linksDomik.sectionLanding,
      //   contentType: 'objectsSaleRoom'
      // },
      // {
      //   builder: linksDomik.sectionLanding,
      //   contentType: 'objectsSaleStoreroom'
      // },
      {
        builder: linksDomik.sectionLanding,
        contentType: 'objectsSaleOffice'
      },
      {
        builder: linksDomik.sectionLanding,
        contentType: 'objectsSaleBusiness'
      },
      {
        builder: linksDomik.sectionLanding,
        contentType: 'objectsSaleGarage'
      },
      {
        builder: linksDomik.sectionLanding,
        contentType: 'objectsSaleLandLiving'
      },
      // {
      //   builder: linksDomik.sectionLanding,
      //   contentType: 'objectsSaleMarketplace'
      // },
      // {
      //   builder: linksDomik.sectionLanding,
      //   contentType: 'objectsSaleFreePurpose'
      // },
      // {
      //   builder: linksDomik.sectionLanding,
      //   contentType: 'objectsSaleWarehouse'
      // },
      // {
      //   builder: linksDomik.sectionLanding,
      //   contentType: 'objectsSaleProduction'
      // },
      // {
      //   builder: linksDomik.sectionLanding,
      //   contentType: 'objectsSaleBusiness'
      // },
      // {
      //   builder: linksDomik.sectionLanding,
      //   contentType: 'objectsSaleBuilding'
      // }
    ]
  },
  // Section: Newbuilds
  {
    builder: linksDomik.sectionLanding,
    contentType: 'allNewbuilds',
    children: [
      {
        builder: linksDomik.sectionLanding,
        contentType: 'flatsNewbuild',
        hamburgerName: 'hamburgerFlatsNewbuild'
      },
      {
        builder: linksDomik.sectionLanding,
        contentType: 'cottageTowns'
      },
      // {
      //   builder: linksDomik.sectionLanding,
      //   contentType: 'onMap'
      // },
      {
        builder: linksDomik.sectionLanding,
        contentType: 'commercialsNewbuild'
      },
      {
        builder: linksDomik.sectionLanding,
        contentType: 'developers'
      },
      {
        builder: linksDomik.sectionLanding,
        contentType: 'stocks'
      }
    ]
  },
  // House catalog
  {
    builder: linksDomik.sectionLanding,
    contentType: 'addresses',
    hide: true,
    name: 'houseCatalog'
  },
  // Section: News
  {
    builder: linksDomik.pageNewsRoot,
    name: 'publications'
  },
  // Section: Forum
  {
    builder: linksForum.sectionForum,
    name: 'forum',
    noRedirector: true
  }
];

export default domikMenu;
