// import menuTranslates from './menu-i18n.json';

export default function () {
  return {
    // ...menuTranslates,

    // logoDescription: {
    //   ru: 'Новостройки Украины',
    //   ua: 'Новобудови України'
    // },

    // aboutDomik: {
    //   ru: 'Про Билд-новостройки',
    //   ua: 'Про Білд-новобудови'
    // },

    askQuestionBtn: {
      ru: 'Написать нам',
      ua: 'Написати нам'
    },
    askQuestionNotification: {
      ru: 'Ваше сообщение отправлено администратору.\nМы ответим на ваш вопрос в ближайшее время.\nСпасибо!',
      ua: 'Ваше повідомлення відправлено адміністратору.\nМи відповімо на ваше запитання найближчим часом.\nДякуємо!'
    },
    askQuestionModalTopic: {
      ru: 'Напишите нам',
      ua: 'Напишіть нам'
    },

    call: {
      ru: 'Позвонить\u00a0',
      ua: 'Дзвонити\u00a0'
    },

    departmentTopic: {
      ru: 'Работаем\u00a0',
      ua: 'Працюємо\u00a0'
    },
    department: {
      ru: 'Пн-Пт с 9:00 до 18:00',
      ua: 'Пн-Пт з 9:00 до 18:00'
    },

    linkToTelegramTitle: {
      ru: 'Перейти в телеграм',
      ua: 'Перейти в телеграм'
    },

    /*
    quickNavigation: {
      ru: 'Быстрая навигация',
      ua: 'Швидка навігація'
    },
    */
    general: {
      ru: 'Общее',
      ua: 'Загальне'
    },
    aboutUs: {
      ru: 'О нас',
      ua: 'Про нас'
    },
    contacts: {
      ru: 'Контакты',
      ua: 'Контакти'
    },
    /*
    advertising: {
      ru: 'Реклама',
      ua: 'Реклама'
    },
    */
    help: {
      ru: 'Помощь',
      ua: 'Допомога'
    },
    /*
    siteMap: {
      ru: 'Карта сайта',
      ua: 'Карта сайту'
    },
    */
    ourProducts: {
      ru: 'Наши продукты',
      ua: 'Наші продукти'
    },
    /*
    termsUser: {
      ru: 'Пользовательское соглашение',
      ua: 'Угода користувача'
    },
    linkToRegistrationConditionTitle: {
      ru: 'Условия регистрации',
      ua: 'Умови реєстрації'
    },
    linkToRegistrationConditionLabel: {
      ru: 'Политика конфиденциальности',
      ua: 'Политика конфіденційності'
    },
    licenseAgreement: {
      ru: 'Лицензионное соглашение',
      ua: 'Ліцензійна угода'
    },
    cookieUsage: {
      ru: 'Использование Cookie',
      ua: 'Використання Cookie'
    },
    */
    termsUse: {
      ru: 'Условия использования',
      ua: 'Умови використання'
    },
    tariffPlans: {
      ru: 'Реклама для застройщиков',
      ua: 'Реклама для забудовників'
    },
    termsUseMaterials: {
      ru: 'Условия использования материалов',
      ua: 'Умови використання матеріалів'
    },
    realEstatePortal: {
      ru: 'Портал недвижимости',
      ua: 'Портал нерухомості'
    },
    /*
    footerDescText: {
      ru: 'Domik.ua - информационно-аналитический ресурс, посвященный рынку недвижимости Киева и Украины и связанным с ним сферам: финансам, профильному законодательству, архитектуре и дизайну.',
      ua: 'Domik.ua - інформаційно-аналітичний ресурс, присвячений ринку нерухомості Києва та України і зв\'язаним з ним сферами: фінансам, профільному законодавству, архітектурі та дизайну'
    },
    */
    footerBottomDescText: {
      ru: 'Все права защищены.',
      ua: 'Всі права захищені.'
    },

    linkToVintageTitle: {
      ru: 'VINTAGE - СОЗДАТЕЛИ ЛУЧШИХ САЙТОВ В МИРЕ',
      ua: 'VINTAGE - ТВОРЦІ КРАЩИХ САЙТІВ В СВІТІ'
    }
  };
}
