/**
 * Created by PIV on 21.06.2016.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { JsonLd } from 'react-schemaorg';
// import map from 'lodash/map';
import get from 'lodash/get';
import replace from 'lodash/replace';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import absoluteUrl from 'helpers/absoluteUrl';
import AskQuestion from 'subsys/ask-question';
import { LogoHeader } from 'components/header';
import Container from 'components/container';
import Row, { Col } from 'components/row';
import Link from 'components/link';
import Icon, { iconTypes } from 'components/icon';
// import { serverMenu } from 'components/header';
import { ModalButton, Content } from 'components/modal';
import LangSwitch from 'components/lang-switch';

import { phoneDomik } from 'src-sites/domik/config';
import { renderLink } from 'src-sites/domik/body/footer-link';
import linksDomik from 'src-sites/domik/links';
import linksBild from 'src-sites/bild/links';
import linksForum from 'src-sites/forum/links';
import linksBildControl from 'src-sites/bildcontrol/links';
// import linksA2 from 'src-sites/a2/links';
import defaultLogoDomik from 'src-sites/domik/assets/logo-domik.jpg';

// import { domikMenu } from './header/header-content';

import translates from './footer-i18n';

import config from '../../../config';

const classesFooter = new Bem('footer');

@I18nHoc(translates())
export default class Footer extends Component {
  static componentCacheConfig = {
    strategy: 'template',
    enable: true
  };

  static propTypes = {
    className: PropTypes.string,
    mainMenu: PropTypes.shape({}),
    location: PropTypes.shape({}),
  };

  static renderLogoContent(logoClasses) {
    return (
      <>
        <Icon {...logoClasses('icon', 'logo')} type={iconTypes.logoDomik} />
      </>
    );
  }

  render() {
    const { i18n } = this;
    const {
      // mainMenu,
      activeRoute
    } = this.props;
    // const footerMenu = serverMenu(domikMenu, mainMenu, i18n);
    const name = 'Domik.ua';
    const urlToFB = config.fbDomik.url;
    const urlToYT = config.yTDomik.url;
    const urlToTelegram = config.telegramlink.url;
    const telText = phoneDomik;
    const foundingDate = `2001-${(new Date()).getFullYear()}`;
    const department = i18n('department');
    const askButtonProps = {
      iconType: iconTypes.comment,
      label: i18n('askQuestionBtn'),
      title: i18n('askQuestionBtn'),
      contentLeft: true,
      dataView: null
    };

    return (
      <footer {...classesFooter(null, null, this.props.className)}>
        <Container body>
          <Row {...classesFooter('row', 'main')} mpColumn>
            <Col {...classesFooter('col', 'first')} allFlex mdColumn allPr={0}>
              <div {...classesFooter('item', 'logoContainer')}>
                <LogoHeader
                  {...classesFooter('logo')}
                  linkBuilder={linksDomik.sectionDomik}
                  builderProps={{ title: 'domik.ua' }}
                  renderContent={Footer.renderLogoContent}
                />
              </div>
              {/*
              <div {...classesFooter('item', 'socials')}>
                <div {...classesFooter('socials')}>
                  <Link
                    {...classesFooter('link', 'social socialFacebook')}
                    href={urlToFB}
                    title="facebook.com"
                    rel="nofollow"
                    noRedirector
                    external
                    blank
                  >
                    <span {...classesFooter('linkContent')}>
                      <Icon {...classesFooter('icon')} type={iconTypes.socialFacebook} />
                    </span>
                  </Link>
                  <Link
                    {...classesFooter('link', 'social socialYoutube')}
                    href={urlToYT}
                    title="instagram.com"
                    rel="nofollow"
                    noRedirector
                    external
                    blank
                  >
                    <span {...classesFooter('linkContent')}>
                      <Icon {...classesFooter('icon')} type={iconTypes.socialYoutube} />
                    </span>
                  </Link>
                  <Link
                    {...classesFooter('link', 'social socialTelegram')}
                    href={urlToTelegram}
                    title={i18n('linkToTelegramTitle')}
                    rel="nofollow"
                    noRedirector
                    external
                    blank
                  >
                    <span {...classesFooter('linkContent')}>
                      <Icon {...classesFooter('icon')} type={iconTypes.telegram} />
                    </span>
                  </Link>
                </div>
              </div>
              <div
                {...classesFooter('item', 'askQuestion')}
                children={renderModalAskUsQuestion.call(this, askButtonProps, classesFooter)}
              />
              */}
              <div {...classesFooter('item', 'tel')}>
                <span {...classesFooter('text')}>
                  {i18n('call')}
                  <Link
                    {...classesFooter('link', 'tel noUnderline')}
                    href={`tel:${replace(telText, /[\s\(\)]/g, '')}`}
                    external
                  >
                    <span {...classesFooter('linkContent')}>
                      <span {...classesFooter('text')} children={telText} />
                    </span>
                  </Link>
                </span>
              </div>
              <div {...classesFooter('item', 'department')}>
                <span {...classesFooter('text')}>
                  {i18n('departmentTopic')}
                  <span children={department} />
                </span>
              </div>
              <div {...classesFooter('item', 'lang')}>
                <LangSwitch activeRoute={activeRoute} switchWithoutPages />
              </div>
            </Col>
            {/*
            <Col {...classesFooter('col', 'quickNavigation')} allFlex allColumn allAuto allPr={0}>
              <div {...classesFooter('item', 'topic')}>
                <span {...classesFooter('text')} children={i18n('quickNavigation')} />
              </div>
              {map(footerMenu, (item, index) => (
                <div {...classesFooter('item')} key={index}>
                  <Link {...classesFooter('link')} {...item}>
                    <span {...classesFooter('linkContent')}>
                      <span {...classesFooter('text')} children={item.name} />
                    </span>
                  </Link>
                </div>
              ))}
            </Col>
            */}
            <Col {...classesFooter('col', 'generalAndOurProductsItem')} allFlex allColumn allAuto allPr={0}>
              <div {...classesFooter('item', 'topic')}>
                <span {...classesFooter('text')} children={i18n('ourProducts')} />
              </div>
              {renderLink.call(null, linksBild.sectionBild, 'Bild.ua', 'Bild.ua', { noIndex: false, noRedirector: true, external: true, blank: true })}
              {renderLink.call(null, linksForum.sectionForum, 'Forum.Domik.ua', 'Forum.Domik.ua', { noIndex: false, noRedirector: true, external: true, blank: false })}
              {renderLink.call(null, linksBildControl.sectionBildControl, 'BildControl.ua', 'BildControl.ua', { noIndex: false, noRedirector: true, external: true, blank: true })}
              {/*
              {renderLink.call(null, linksA2.sectionA2, 'A2.kiev.ua', 'A2.kiev.ua', { noIndex: false, noRedirector: true, external: true, blank: true })}
              */}
            </Col>
            <Col {...classesFooter('col', 'generalAndOurProducts')} allFlex allColumn allAuto allPr={0}>
              <Row {...classesFooter('row', 'generalAndOurProducts')} mdColumn mtColumn>
                <Col {...classesFooter('col', 'generalAndOurProductsItem')} allFlex allColumn allAuto allPr={0}>
                  <div {...classesFooter('item', 'topic')}>
                    <span {...classesFooter('text')} children={i18n('general')} />
                  </div>
                  {renderLink.call(null, linksDomik.sectionAbout, i18n('aboutUs'), i18n('aboutUs'), { rel: '', noIndex: false })}
                  {renderLink.call(null, linksDomik.sectionContacts, i18n('contacts'), i18n('contacts'), { rel: '', noIndex: false })}
                  {/*
                  <div {...classesFooter('item')}><Link {...classesFooter('link')} href="#"><span {...classesFooter('text')}>{i18n('advertising')}</span></Link></div>
                  <div {...classesFooter('item')}><Link {...classesFooter('link')} href="#"><span {...classesFooter('text')}>{i18n('help')}</span></Link></div>
                  <div {...classesFooter('item')}><Link {...classesFooter('link')} href="#"><span {...classesFooter('text')}>{i18n('siteMap')}</span></Link></div>
                  */}
                  {renderLink.call(null, linksDomik.sectionTermsUse, i18n('termsUse'), i18n('termsUse'), { rel: '', noIndex: false })}
                  {renderLink.call(null, linksDomik.sectionTariffPlans, i18n('tariffPlans'), i18n('tariffPlans'), { rel: '', noIndex: false })}
                </Col>
              </Row>
            </Col>
            <Col {...classesFooter('col', 'last')} allFlex allColumn allAuto allPr={0}>
              <div {...classesFooter('item', 'socials')}>
                <div {...classesFooter('socials')}>
                  <Link
                    {...classesFooter('link', 'social socialFacebook')}
                    href={urlToFB}
                    title="facebook.com"
                    rel="nofollow"
                    noRedirector
                    external
                    blank
                  >
                    <span {...classesFooter('linkContent')}>
                      <Icon {...classesFooter('icon')} type={iconTypes.socialFacebook} />
                    </span>
                  </Link>
                  <Link
                    {...classesFooter('link', 'social socialYoutube')}
                    href={urlToYT}
                    title="instagram.com"
                    rel="nofollow"
                    noRedirector
                    external
                    blank
                  >
                    <span {...classesFooter('linkContent')}>
                      <Icon {...classesFooter('icon')} type={iconTypes.socialYoutube} />
                    </span>
                  </Link>
                  <Link
                    {...classesFooter('link', 'social socialTelegram')}
                    href={urlToTelegram}
                    title={i18n('linkToTelegramTitle')}
                    rel="nofollow"
                    noRedirector
                    external
                    blank
                  >
                    <span {...classesFooter('linkContent')}>
                      <Icon {...classesFooter('icon')} type={iconTypes.telegram} />
                    </span>
                  </Link>
                </div>
              </div>
              <div
                {...classesFooter('item', 'askQuestion')}
                children={renderModalAskUsQuestion.call(this, askButtonProps, classesFooter)}
              />
              {/*
              {renderLink.call(null, linksDomik.sectionTerms, i18n('termsUser'), i18n('termsUser'), { rel: '', noIndex: false })}
              {renderLink.call(null, linksDomik.sectionRegistrationCondition, i18n('linkToRegistrationConditionTitle'), i18n('linkToRegistrationConditionLabel'), { rel: '', noIndex: false })}
              <div {...classesFooter('item')}><span {...classesFooter('text')}>{i18n('licenseAgreement')}</span></div>
              <div {...classesFooter('item')}><span {...classesFooter('text')}>{i18n('cookieUsage')}</span></div>
              <div {...classesFooter('item')}><span {...classesFooter('text')}>{i18n('termsUseMaterials')}</span></div>
              <div {...classesFooter('item', 'text')}>
                <span {...classesFooter('text')} children={`${i18n('realEstatePortal')} ${i18n('footerDescText')}`} />
              </div>
              */}
            </Col>
          </Row>
        </Container>
        <div {...classesFooter('bottom')}>
          <Container body>
            <Row {...classesFooter('row', 'bottom')}>
              <Col {...classesFooter('col', 'copy')} allPr={0}>
                <div {...classesFooter('item', 'copyContainer')}>
                  <span {...classesFooter('text')}>
                    &copy; {`${foundingDate}\u00a0${i18n('realEstatePortal')} ${name}\u00ae ${i18n('footerBottomDescText')}`}
                  </span>
                </div>
              </Col>
              <Col {...classesFooter('col', 'logoV')} allAuto allPr={0}>
                <div {...classesFooter('item', 'logoV')}>
                  <Link
                    {...classesFooter('link', 'logoV noUnderline')}
                    href="//vintage.com.ua"
                    title={i18n('linkToVintageTitle')}
                    rel="nofollow"
                    external
                    blank
                  >
                    <span {...classesFooter('linkContent')}>
                      <span {...classesFooter('text')} children="by" />
                      <Icon {...classesFooter('icon', 'logoV')} type={iconTypes.logoV} />
                    </span>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <JsonLd
          item={{
            '@context': 'https://schema.org',
            '@type': 'Organization',
            logo: absoluteUrl(defaultLogoDomik),
            name,
            url: absoluteUrl(''),
            telephone: telText,
            department,
            foundingDate,
            contactPoint: {
              telephone: telText,
              contactType: 'customer service',
              hoursAvailable: [
                {
                  closes: '18:00:00',
                  dayOfWeek: 'https://schema.org/Monday',
                  opens: '09:00:00'
                }, {
                  closes: '18:00:00',
                  dayOfWeek: 'https://schema.org/Tuesday',
                  opens: '09:00:00'
                }, {
                  closes: '18:00:00',
                  dayOfWeek: 'https://schema.org/Wednesday',
                  opens: '09:00:00'
                }, {
                  closes: '18:00:00',
                  dayOfWeek: 'https://schema.org/Thursday',
                  opens: '09:00:00'
                }, {
                  closes: '18:00:00',
                  dayOfWeek: 'https://schema.org/Friday',
                  opens: '09:00:00'
                }
              ]
            },
            sameAs: [urlToFB, urlToYT, urlToTelegram]
          }}
        />
      </footer>
    );
  }
}

export function renderModalAskUsQuestion(askButtonProps, classes) {
  const { i18n } = this;
  const seoLocation = get(this, 'props.location.pathname');

  return (
    <ModalButton
      {...classes('btn', 'toAskQuestion')}
      size="big"
      disableNotification={false}
      buttonProps={askButtonProps}
    >
      <Content closeButton noPadding adaptiveHeight>
        <div {...classes('modalContent')}>
          <AskQuestion
            {...classes('blockAskQuestion')}
            controller="support/ask-question/ask-us-question"
            entity="ask_question"
            url={seoLocation}
            otherProps={{
              notificationMessageSuccess: i18n('askQuestionNotification'),
              inputPhoneProps: { noLabel: true, noElementBefore: true },
              additionalMain: true,
              headerTopic: true,
              headerTopicText: i18n('askQuestionModalTopic')
            }}
          />
        </div>
      </Content>
    </ModalButton>
  );
}
