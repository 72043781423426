import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import Bem from 'bemHelper';
import { dictConnect } from 'api/data-dict';
import Link from 'components/link';

import linksDomik from 'src-sites/domik/links';

const classesBem = new Bem('exchangeRate');

@dictConnect({
  url: 'currencies',
  injectPropName: 'currencies'
})
export default class ExchangeRate extends Component {
  static propTypes = {
    className: PropTypes.string,
    currencies: PropTypes.arrayOf(PropTypes.shape({})),
    linkProps: PropTypes.shape({})
  }

  render() {
    const { className, currencies, linkProps = {} } = this.props;
    const index = findIndex(currencies, currency => currency.id === "usd");
    const exchange_buy = get(currencies, `[${index}].exchange_buy`);
    const exchange_sell = get(currencies, `[${index}].exchange_sell`);

    if (!exchange_buy || !exchange_sell) return null;

    return (
      <div {...classesBem({ extra: className })}>
        <Link
          {...classesBem('link', 'toExchangeRate')}
          builder={linksDomik.sectionConverter}
          builderProps={{ title: linkProps.title }}
          noRedirector
        >
          <span {...classesBem('linkContent')}>
            <span
              {...classesBem('text')}
              children={`1$ = ${exchange_buy}/${exchange_sell}`}
            />
          </span>
        </Link>
      </div>
    );
  }
}
