import React, { Component } from 'react';
import LazyHydrate from 'react-lazy-hydration';
import get from 'lodash/get';
import filter from 'lodash/filter';
// import map from 'lodash/map';
// import findIndex from 'lodash/findIndex';
import PropTypes from 'prop-types';
import { routerShape } from 'react-router';
import { connect } from 'react-redux';
import { authConnect, UserProp, perms } from 'auth';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import {
  withSideMenu,
  // MenuTopBtn,
  LogoHeader,
  // SearchInput,
  // FavoritesDropdown,
  // Compare,
  // PostAds,
  Menu,
  //buildMenu,
  Account,
  FavoritesLink,
  serverMenu
} from 'components/header';
import SearchIndex from 'components/search-index';
import Icon, { iconTypes } from 'components/icon';
// import Link from 'components/link';
import Button from 'components/button';
import Accordion from 'components/accordion';

import linksDomik from 'src-sites/domik/links';

import * as actions from 'visual-state/redux';

import SideMenu from './side-menu';
import Hamburger from './hamburger';
import { renderLinkObjectAdd } from './render-common';

import translates from './header-i18n';

const classes = new Bem('headerContent');
const bemClassesSearchInput = new Bem('searchInput');
const bemClassesAccordion = new Bem('headerAccordion');
// const bemClassesFavorites = new Bem('favorites');
const bemClassesAccount = new Bem('account');
// const bemClassesHamburger = new Bem('hamburger');
// const bemClassesDropdown = new Bem('dropdown');
// const bemClassesMenuTopBtn = new Bem('menuTopBtn');

import domikMenu from './menuContent';

@authConnect()
@withSideMenu
@connect(state => ({
  notification: state.notification,
  visualState: state.visualState
}), { ...actions })
@I18nHoc(translates())
export default class DomikHeaderContent extends Component {
  static propTypes = {
    className: PropTypes.string,
    mainMenu: PropTypes.shape({}),
    rubricTree: PropTypes.arrayOf(PropTypes.shape({})),
    rootForums: PropTypes.arrayOf(PropTypes.shape({})),

    // Says which device is now active:
    desktop: PropTypes.bool, // desktop
    tablet: PropTypes.bool, // tablet
    phone: PropTypes.bool, // phone

    // Auth props
    auth: UserProp,
    actionAuthLogout: PropTypes.func,
    checkPermission: PropTypes.func,
    checkPermissions: PropTypes.func,

    // Open sideMenu method
    onOpenMenu: PropTypes.func,

    // Close sideMenu method
    onCloseMenu: PropTypes.func,

    // Says sideMenu is opened
    menuOpened: PropTypes.bool,

    activeRoute: PropTypes.shape({}),

    // Says whether user clicked account button
    accountClicked: PropTypes.bool,

    notification: PropTypes.shape({
      count: PropTypes.number
    }),
    landing: PropTypes.shape({}),

    location: PropTypes.shape({})
  };

  static contextTypes = {
    router: routerShape
  };

  static renderNoAuthContent() {
    return (
      <Icon
        {...bemClassesAccount('icon')}
        type={iconTypes.personCircle}
      />
    );
  }

  constructor(props, ctx) {
    super(props, ctx);
    this.renderSearch = this.renderSearch.bind(this);
    this.searchItemSelected = this.searchItemSelected.bind(this);
    this.showMobileSearch = this.showMobileSearch.bind(this);
    this.isFirstLoad = this.isFirstLoad.bind(this);

    this.router = ctx.router;

    this.state = {
      isFirstLoad: true,
      openedHamburger: false
    }
  }

  componentDidMount() {
    this.isFirstLoad();
  }

  isFirstLoad() {
    this.setState({ isFirstLoad: false })
  }

  searchItemSelected(it) {
    if (!this.props.desktop) {
      this.accordRef.toggle();
      this.searchRef.toggleFocus();
    }
  }

  showMobileSearch() {
    this.accordRef.toggle();
    this.searchRef.toggleFocus();
  }

  onOpenHamburger = () => this.setState({ openedHamburger: true });

  onCloseHamburger = () => this.setState({ openedHamburger: false });

  renderLogoContent(logoClasses) {
    return (
      <>
        <Icon {...logoClasses('icon', 'logo')} type={iconTypes.logoDomik} />
      </>
    );
  }

  renderSearch() {
    return (
      <SearchIndex
        {...classes('search')}
        wRef={el => this.searchRef = el}
        iconPlaceholder=""
        placeholder={this.i18n('searchPlaceholder')}
        queryParams={{
          landing: true,
          entities: ['newbuild', 'developer'],
          order: [['relevancy', 'desc']]
        }}
        linkBuilder={linksDomik.sectionLanding}
        onItemSelected={this.searchItemSelected}
        searchBoxContent={<Icon {...bemClassesSearchInput('icon', 'search')} type={iconTypes.searchBild} />}
        noError
      />
    );
  }

  renderAccordion(type) {
    return (
      <Accordion
        {...bemClassesAccordion()}
        ref={el => this.accordRef = el}
        noHeaderButton
      >
        <div {...bemClassesAccordion('content')} children={type === 'search' && this.renderSearch()} />
      </Accordion>
    );
  }

  toggleSearchForm =()=> {
    const showPhoneSearchOnMap = get(this, 'props.visualState.showPhoneSearchOnMap');
    this.props.togglePhoneSearchOnMap(!showPhoneSearchOnMap);
  };

  renderSearchButton(func) {
    return (
      <Button
        {...classes('btn', 'search accordion')}
        onClick={func}
        iconOnly
        customRenderer
      >
        <span {...classes('btnContent')}>
          <Icon
            {...classes('icon', 'search')}
            type={iconTypes.searchBild}
          />
        </span>
      </Button>
    )
  }

  render() {
    const { i18n } = this;
    const {
      accountClicked,
      desktop, tablet, phone,
      onOpenMenu, onCloseMenu, menuOpened,
      auth, actionAuthLogout, checkPermission, checkPermissions, className,
      mainMenu, activeRoute, rubricTree, rootForums, location /*notification = {}*/
    } = this.props;
    const { isFirstLoad, openedHamburger } = this.state;
    const sideMenuProps = {};
    if (desktop) {
      sideMenuProps.renderSearch = this.renderSearch;
      sideMenuProps.socialsAndPhones = false;
    }
    if (!desktop) {
      sideMenuProps.socialsAndPhones = false;
    }

    const isAuth = checkPermission(perms.AUTH);

    const btnAccount = (
      <Account
        {...classes('account')}
        onAccountClick={isAuth ? onOpenMenu : null}
        renderContent={DomikHeaderContent.renderNoAuthContent}
      />
    );
    const builtMenu = serverMenu(domikMenu, mainMenu, i18n);
    const filteredBuiltMenu = filter(builtMenu, child => child.hide !== true);

    return (
      <div {...classes({ extra: className })}>
        <LogoHeader
          linkBuilder={linksDomik.sectionDomik}
          builderProps={{ title: 'domik.ua' }}
          renderContent={this.renderLogoContent}
        />
        {!openedHamburger &&
          <>
            {desktop &&
              <>
                {!activeRoute.noHeaderSearch && this.renderSearch()}
                <Menu
                  {...classes('menu')}
                  mainMenu={filteredBuiltMenu}
                  activeRoute={activeRoute}
                />
              </>
            }
            {!activeRoute.noHeaderSearch && !desktop &&
              this.renderSearchButton(this.showMobileSearch)
            }
            {activeRoute.mainSearch && phone &&
              this.renderSearchButton(this.toggleSearchForm)
            }
            {!activeRoute.noHeaderSearch && isFirstLoad &&
              <div {...classes('btn', 'search accordion dummy', 'btn')}>
                <span {...classes('btnContent', null, 'btn__content')}>
                  <Icon
                    {...classes('icon', 'search')}
                    type={iconTypes.searchBild}
                  />
                </span>
              </div>
            }
          </>
        }
        <FavoritesLink
          builder={linksDomik.pageFavorites}
          builderProps={{ project: 'domik' }}
          otherPropsLink={{
            blank: false
          }}
        />
        {isFirstLoad &&
          <>
            <div {...classes('account', 'dummy', 'account')}>
              <div {...bemClassesAccount('btn', 'account', 'btn')}>
                <span {...bemClassesAccount('btnContent', null, 'btn__content')}>
                  {DomikHeaderContent.renderNoAuthContent()}
                </span>
              </div>
            </div>
            {/*
            <div {...classes('hamburger', 'dummy', 'dropdown hamburger')}>
              <div {...bemClassesHamburger('btn', 'hamburger', 'btn dropdown__btn')}>
                <span {...bemClassesDropdown('btnContent', null, 'btn__content')}>
                  <Icon
                    {...bemClassesDropdown('icon')}
                    type={iconTypes.menuDomik}
                  />
                </span>
              </div>
            </div>
            */}
          </>
        }
        {btnAccount}
        <Hamburger
          {...classes('hamburger')}
          onOpen={this.onOpenHamburger}
          onClose={this.onCloseHamburger}
          activeRoute={activeRoute}
          mainMenu={builtMenu}
          rubricTree={rubricTree}
          rootForums={rootForums}
          location={location}
        />
        {(desktop || tablet) && renderLinkObjectAdd(classes, i18n('objectAdd'))}
        {/*
          <Account onAccountClick={onOpenMenu} />
          <Compare />
          <FavoritesDropdown />
          <PostAds
            builder={links.sectionAdd}
            builderProps={{ name: 'Объявление', title: 'Подать объявление' }}
          />
        */}
        <LazyHydrate whenIdle>
          {auth.authenticated &&
            <SideMenu
              auth={auth}
              actionAuthLogout={actionAuthLogout}
              desktop={true}
              phone={phone}
              accountClicked={accountClicked}
              menuOpened={menuOpened}
              onMenuClose={onCloseMenu}
              mainMenu={filteredBuiltMenu}
              activeRoute={activeRoute}
              checkPermission={checkPermission}
              checkPermissions={checkPermissions}
              location={location}
              {...sideMenuProps}
            />
          }
          {!activeRoute.noHeaderSearch && !desktop && this.renderAccordion('search')}
        </LazyHydrate>
      </div>
    );
  }
}
