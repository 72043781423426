import loadable from '@loadable/component';
import React, { Component } from 'react';
import LazyHydrate from 'react-lazy-hydration';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Bem from 'bemHelper';
import { I18nHoc, LangContext } from 'helpers/i18n';
import appMessage from 'helpers/appMessage';
import preload from 'api/data-preload';
import Dict from 'api/data-dict';
import DataProvider from 'api/data-provider';
import GeoLandings from 'subsys/geo-landings';
import Container from 'components/container';
import Row, { Col } from 'components/row';
// import HeaderTop from 'components/header-top';
import ScrollTopBtn from 'components/scroll-top-btn';
import { BannerAffix, BannerHeader } from 'components/banners';
import { checkUserLang } from 'components/lang-switch';

import HelmetI18n from './helmet-i18n';
import Header from './header/header';
import Footer from './footer';

// import config from '../../../config';

import translates from './body-i18n.json';

// *** Deferred-load components
const LoginModal = loadable(() => import(/* webpackChunkName: "body-loginModal" */ 'components/login-modal'));
const ManagementToolbar = loadable(() => import(/* webpackChunkName: "body-managementToolbar" */ 'subsys/management-toolbar'));

import 'styles/domik/body/_body.scss';
import 'styles/domik/body/_footer.scss';

const bemClasses = new Bem('body');

@preload({
  mainMenuBase: [DataProvider.preload, {
    url: 'core/menu',
    cache: 60 * 24,
    query: {
      project: 'domik'
    }
  }],
  publRubricTreeMenu: [Dict.preload, { url: 'domik/publications/news-rubrics-tree' }],
  rootForumsMenu: [DataProvider.preload, {
    url: 'forum/forum',
    cache: 120,
    query: {
      'root-forums': 1,
      'order-by': 'weight'
    }
  }]
}, { noReload: true })
@I18nHoc(translates)
export default class DomikBody extends Component {
  static propTypes = {
    router: PropTypes.shape({}),
    location: PropTypes.shape({}),
    routes: PropTypes.arrayOf(PropTypes.shape({})),
    mainMenuBase: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({}))
    }),
    publRubricTreeMenu: PropTypes.shape({}),
    rootForumsMenu: PropTypes.shape({}),
    children: PropTypes.node
  };

  static selectors = ['html', 'body'];

  componentDidMount() {
    if (__APP__) appMessage({ render: 'done' });
    checkUserLang.call(this);
  }

  render() {
    const { i18n } = this;
    const {
      routes = [], mainMenuBase, children, location, params = {},
      publRubricTreeMenu, rootForumsMenu
    } = this.props;
    const activeRoute = routes[routes.length - 1];
    activeRoute['query'] = get(this, 'props.router.location.query');
    activeRoute['page'] = get(this, 'props.router.params.page');

    return (
      <LangContext activeRoute={activeRoute}>
        <Row {...bemClasses(null, activeRoute.mods)} allColumn noMaxW>
          <Col {...bemClasses('content')} allFlex allPr={0}>
            <LazyHydrate whenIdle>
              <HelmetI18n location={location} />
            </LazyHydrate>
            <Row allColumn hFull noMaxW>
              <Col {...bemClasses('mainContainer')} allFlex allPr={0}>
                <div {...bemClasses('headerContainer')}>
                  <GeoLandings
                    base={{}}
                  >
                    <BannerHeader activeRoute={activeRoute} />
                  </GeoLandings>
                  <GeoLandings
                    injectPropName="mainMenu"
                    base={mainMenuBase}
                  >
                    <Dict
                      url="domik/publications/news-rubrics-tree"
                      injectPropName="rubricTree"
                      preloaded={publRubricTreeMenu}
                      injectFromLoader={['mainMenu']}
                    >
                      <DataProvider
                        url="forum/forum"
                        injectPropName="rootForums"
                        injectFromLoader={['mainMenu', 'rubricTree']}
                        cache={120}
                        query={{
                          'root-forums': 1,
                          'order-by': 'weight'
                        }}
                        preloaded={rootForumsMenu}
                      >
                        <Header
                          {...bemClasses('header')}
                          activeRoute={activeRoute}
                          landing={params ? params.landing : null}
                          location={location}
                        />
                      </DataProvider>
                    </Dict>
                  </GeoLandings>
                </div>
                <Container {...bemClasses('main')} body>
                  {React.Children.map(children, child =>
                    React.cloneElement(child, { ...child.props, mainMenuBase })
                  )}
                </Container>
              </Col>
            </Row>

            <LazyHydrate whenIdle>
              <ScrollTopBtn {...bemClasses('scrollTopBtn')} />
              <GeoLandings
                base={{}}
              >
                <BannerAffix activeRoute={activeRoute} />
              </GeoLandings>
            </LazyHydrate>

            <LazyHydrate whenVisible>
              {!activeRoute.noFooter &&
                <GeoLandings
                  base={{}}
                >
                  <Footer
                    {...bemClasses('footer')}
                    location={location}
                    activeRoute={activeRoute}
                  />
                </GeoLandings>
              }
            </LazyHydrate>

            <LazyHydrate whenIdle>
              <LoginModal />
              <ManagementToolbar />
            </LazyHydrate>
            <noscript {...bemClasses('noJS')} children={i18n('noscript')} />
          </Col>
        </Row>
      </LangContext>
    );
  }
}
