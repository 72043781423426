import menuBaseTranslates from 'components/header/side-menu/menu-i18n.json';

import menuTranslates from '../menu-i18n.json';
import footerTranslates from '../footer-i18n';

export default function () {
  return {
    ...menuBaseTranslates,
    ...menuTranslates,
    ...footerTranslates(),

    btnLogout: {
      ru: 'Выйти',
      ua: 'Вийти'
    },
    searchPlaceholder: {
      ru: 'Название ЖК или Застройщика',
      ua: 'Назва ЖК або Забудовника'
    },

    call: {
      ru: 'Позвонить',
      ua: 'Дзвонити'
    },
    departmentTopic: {
      ru: 'Работаем',
      ua: 'Працюємо'
    },
    department: {
      ru: 'Пн-Пт с 9:00 до 18:00',
      ua: 'Пн-Пт з 9:00 до 18:00'
    },
    supportUser: {
      ru: 'Поддержка пользователей',
      ua: 'Підтримка користувачів'
    },
    /*
    websiteAdvertising: {
      ru: 'Реклама на сайте',
      ua: 'Реклама на сайті'
    },
    */
    websiteAboutHouses: {
      ru: 'Отзывы о домах',
      ua: 'Відгуки про будинки'
    },
    myObjects: {
      ru: 'Мои объявления',
      ua: 'Мої оголошення'
    },
    billing: {
      ru: 'Пополнение счёта',
      ua: 'Поповнення рахунку'
    },
    converter: {
      ru: 'Конвертер валют',
      ua: 'Конвертер валют'
    },
    myProfile: {
      ru: 'Личные данные',
      ua: 'Особисті дані'
    },
    objectAdd: {
      ru: 'Подать объявление',
      ua: 'Подати оголошення'
    },
    askQuestionBtn: {
      ru: 'Поддержка пользователей',
      ua: 'Підтримка користувачів'
    }
  };
}
